@import "_variables.scss";
@import "~uikit/src/scss/variables-theme.scss";
@import "~uikit/src/scss/mixins-theme.scss";
@import "~uikit/src/scss/uikit-theme.scss";
@import "_custom.scss";

// TODO: these aux uses sass variable, and they dont work in _custom for some reason. dont know why
.uk-flex-gap-xsmall, .uk-flex-gap-xs {
    gap: $global-xsmall-margin;
}
.uk-flex-gap-small, .uk-flex-gap-s {
    gap: $global-small-margin;
}

.uk-flex-gap, .uk-flex-gap-medium, .uk-flex-gap-m {
    gap: $global-margin;
}

.uk-flex-gap-large, .uk-flex-gap-l {
    gap: $global-large-margin;
}
.uk-flex-gap-xlarge, .uk-flex-gap-xl {
    gap: $global-xlarge-margin;
}
* {
    box-sizing: border-box;
}
