



.uk-text-inverse {
    color: var(--global-inverse-color);
}

.uk-form-horizontal.uk-form-horizontal--tight {
    .uk-form-label {
        width: calc(var(--global-margin) * 5);
    }
    .uk-form-controls {
        margin-left: calc(var(--global-margin) * 5.5);
    }
}

.radios-horizontal .input-inner-wrapper {
    display: flex;

    > * {
        margin-inline-end: calc(var(--global-margin) * 0.5);
        min-width: calc(var(--global-margin) * 4);
    }
}

// aux
/*

                      $global-small-margin !default;
                    global-medium-margin !default;
                     $global-medium-margin !default;
                   $global-large-margin !default;
                    $global-large-margin !default;
               $global-xlarge-margin !default;
 */

