@use "sass:math";
//define all "hardcoded" framework variables as css variables,
// NOTICE: due to color interpolation, some vars MUST still be SCSS vars
:root {
    --global-font-size: 16px;
    --global-margin: 20px;
    --global-inverse-color: #fff;
    --global-color: #666;
    --global-muted-background: #f8f8f8;
    --global-primary-background: #1e87f0;
    --global-success-background: #32d296;
    --global-warning-background: #faa05a;
    --global-danger-background: #f0506e;
    --global-muted-color: #999;
    --global-background: #fff;
    --global-secondary-background: #222;
    --global-link-color: #1e87f0;
    --global-link-hover-color: #0f6ecd;
    --base-ins-background: #ffd;
    --base-mark-background: #ffd;
    --global-border: #e5e5e5;
    --base-selection-background: #39f;
    --lightbox-background: #000;
    --lightbox-item-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-background: rgba(0, 0, 0, 0.3);
    --lightbox-toolbar-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-icon-color: rgba(255, 255, 255, 0.7);
    --lightbox-toolbar-icon-hover-color: #fff;
    --lightbox-button-color: rgba(255, 255, 255, 0.7);
    --lightbox-button-hover-color: #fff;
    --modal-background: rgba(0, 0, 0, 0.6);
    --table-row-active-background: #ffd;
    --tooltip-background: #666;
    --box-shadow-bottom-background: #444;
    --dragover-box-shadow: 0 0 20px rgba(100, 100, 100, 0.3);
    --global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    --global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    --global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    --global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16);
    --dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    --navbar-dropdown-box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15);
    --navbar-dropbar-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.05);

}






//now, set the framework SCSS vars, to use the css variables. this way, app can implement themes!
// Used in margin, section, list
$global-xsmall-margin:                            5px;
$global-small-margin:                            $global-xsmall-margin*2; // 10px
$global-margin:                                  $global-small-margin*2; // 20px
$global-large-margin:                            $global-margin*3.5;
$global-xlarge-margin:                           $global-large-margin*2;
$global-gutter:                                  $global-margin*1.5;
$global-small-gutter:                            $global-margin*0.75;
$global-medium-gutter:                           $global-margin*2;
$global-large-gutter:                            $global-margin*3.5;
$global-control-height:                          $global-margin*2;
$global-control-small-height:                    $global-margin*1.5;
$global-control-large-height:                    $global-margin*2.75;


$global-inverse-color: #fff;
$global-color: #666;
$global-muted-background: #f8f8f8;
$global-primary-background: #1e87f0;
$global-success-background: #1e87f0;
$global-warning-background: #faa05a;
$global-danger-background: #f0506e;
$global-muted-color: #999;
$global-background: #fff;
$global-secondary-background: #222;
$global-border: #e5e5e5;
$global-margin: 20px;
$global-font-size: 16px;

$global-link-color: var(--global-link-color);
$global-link-hover-color: var(--global-link-hover-color);
$base-ins-background: var(--base-ins-background);
$base-mark-background: var(----base-mark-background);

$base-selection-background: var(--base-selection-background);
$lightbox-background: var(--lightbox-background);
$lightbox-item-color: var(--lightbox-item-color);
$lightbox-toolbar-background: var(--lightbox-toolbar-background);
$lightbox-toolbar-color: var(--lightbox-toolbar-color);
$lightbox-toolbar-icon-color: var(--lightbox-toolbar-icon-color);
$lightbox-toolbar-icon-hover-color: var(--lightbox-toolbar-icon-hover-color);
$lightbox-button-color: var(--lightbox-button-color);
$lightbox-button-hover-color: var(--lightbox-button-hover-color);
$modal-background: var(--modal-background);
$table-row-active-background: var(--table-row-active-background);
$tooltip-background: var(--tooltip-background);
$box-shadow-bottom-background: var(--box-shadow-bottom-background);
$dragover-box-shadow: var(--dragover-box-shadow);
$global-small-box-shadow: var(--global-small-box-shadow);
$global-medium-box-shadow: var(--global-medium-box-shadow);
$global-large-box-shadow: var(--global-large-box-shadow);
$global-xlarge-box-shadow: var(--global-xlarge-box-shadow);
$dropdown-box-shadow: var(--dropdown-box-shadow);
$navbar-dropdown-box-shadow: var(--navbar-dropdown-box-shadow);
$navbar-dropbar-box-shadow: var(--navbar-dropbar-box-shadow);





